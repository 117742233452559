"use strict";

import Swiper from "swiper/dist/js/swiper.min.js";

class Common {
  init() {
    window.addEventListener(
      "load",
      () => {
        this.toggleHeaderMenu();
        this.fixedHeader();
        this.fadeIn();
        this.fadeInFv();
        this.hiddenBnr();
        this.closeBnr();
        this.ieModal();
      },
      false
    );

    window.addEventListener(
      "scroll",
      () => {
        this.fixedHeader();
        this.fadeIn();
        this.hiddenBnr();
      },
      false
    );

    this.footerSlide();
  }

  toggleHeaderMenu() {
    const body = document.querySelector("body");
    const headerMenu = document.querySelector(".header__nav");
    const menuItemHusSub = document.querySelector(".header__menu-item.has-sub");
    const subMenu = document.querySelector(".header__submenu");
    const spMenuBtn = document.querySelector(".header__drawer-btn");
    const overlay = document.querySelector(".header__overlay");
    const toggleAttr = (elm, attrSuffix) => {
      let attr = elm.getAttribute("aria-" + attrSuffix);
      return attr == "true" ? "false" : "true";
    };

    const toggleSubMenu = () => {
      menuItemHusSub.setAttribute(
        "aria-expanded",
        toggleAttr(menuItemHusSub, "expanded")
      );
      subMenu.setAttribute("aria-hidden", toggleAttr(subMenu, "hidden"));
    };

    const toggleSpMenu = () => {
      spMenuBtn.setAttribute(
        "aria-expanded",
        toggleAttr(spMenuBtn, "expanded")
      );
      headerMenu.setAttribute("aria-hidden", toggleAttr(headerMenu, "hidden"));

      if (spMenuBtn.getAttribute("aria-expanded") == "true") {
        body.classList.add("is-fixed");
        overlay.classList.add("is-show");
      } else {
        body.classList.remove("is-fixed");
        overlay.classList.remove("is-show");
      }

      const ms = 150;
      if (headerMenu.getAttribute("aria-hidden") == "true") {
        headerMenu.style.visibility = "visible";
        headerMenu.style.opacity = 1;
        headerMenu.style.transition = "all " + ms + "ms";
        setTimeout(() => {
          headerMenu.style.visibility = "hidden";
          headerMenu.style.opacity = 0;
        }, 1);
      } else {
        headerMenu.style.visibility = "hidden";
        headerMenu.style.opacity = 0;
        headerMenu.style.transition = "all " + ms + "ms";
        setTimeout(() => {
          headerMenu.style.visibility = "visible";
          headerMenu.style.opacity = 1;
        }, 1);
      }
    };

    window.addEventListener("resize", () => {
      if (window.matchMedia("(max-width: 1079px)").matches) {
        if (headerMenu.getAttribute("aria-hidden") == "true") {
          headerMenu.style.visibility = "hidden";
          headerMenu.style.opacity = 0;
          headerMenu.style.transition = "unset";
        } else {
          headerMenu.style.visibility = "visible";
          headerMenu.style.opacity = 1;
          headerMenu.style.transition = "unset";
        }
      } else {
        headerMenu.style.visibility = "visible";
        headerMenu.style.opacity = 1;
        headerMenu.style.transition = "unset";
      }
    });

    menuItemHusSub.addEventListener("click", (e) => {
      if (window.matchMedia("(max-width: 1079px)").matches) {
        toggleSubMenu();
        e.stopPropagation();
      }
      menuItemHusSub.classList.toggle("active");
    });
    subMenu.addEventListener("click", (e) => {
      e.stopPropagation(); // イベント伝播を停止
      // subMenu 独自の処理 (もしあれば)
    });
    document.addEventListener("click", (event) => {
      // クリックされた要素がmenuItemHusSub自身またはその子要素でない場合のみactiveクラスを削除
      if (!menuItemHusSub.contains(event.target)) {
        menuItemHusSub.classList.remove("active");
      }
    });

    spMenuBtn.addEventListener("click", (e) => {
      if (window.matchMedia("(max-width: 1079px)").matches) {
        toggleSpMenu();
        e.stopPropagation();
      }
    });

    body.addEventListener("click", (e) => {
      if (window.matchMedia("(max-width: 1079px)").matches) {
        if (spMenuBtn.getAttribute("aria-expanded") == "true") {
          toggleSpMenu();
          e.stopPropagation();
        }
      }
    });

    const uri = window.location.pathname; // 例: /category/item/123
    const uriParts = uri.split("/");
    const firstLevelValue = uriParts[1];
    body.id = firstLevelValue;
  }

  fixedHeader() {
    const header = document.querySelector(".header");
    const scrollPos = window.pageYOffset;
    if (scrollPos > 100) {
      header.classList.add("is-scroll");
    } else {
      header.classList.remove("is-scroll");
    }
  }

  footerSlide() {
    let footerSwiper, footerSwiperBool;

    window.addEventListener(
      "load",
      () => {
        if (window.matchMedia("(min-width: 768px)").matches) {
          createFooterSwiper();
          footerSwiperBool = true;
        } else {
          footerSwiperBool = false;
        }
      },
      false
    );

    window.addEventListener(
      "resize",
      () => {
        if (
          window.matchMedia("(min-width: 768px)").matches &&
          !footerSwiperBool
        ) {
          createFooterSwiper();
          footerSwiperBool = true;
        } else if (
          window.matchMedia("(max-width: 767px)").matches &&
          footerSwiperBool
        ) {
          footerSwiper.destroy();
          footerSwiperBool = false;
        }
      },
      false
    );

    const createFooterSwiper = () => {
      footerSwiper = new Swiper(".footer .swiper-container", {
        speed: 500,
        loop: false,
        slidesPerView: 4,
        spaceBetween: 24,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1239: {
            speed: 500,
            loop: false,
            effect: "fade",
            slidesPerView: 3,
            spaceBetween: 24,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          },

          1079: {
            speed: 500,
            loop: false,
            effect: "fade",
            slidesPerView: 2,
            spaceBetween: 24,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          },
        },
      });
    };
  }

  fadeIn() {
    const targets = document.querySelectorAll(".fade-in");
    const targetsArr = Array.prototype.slice.call(targets);
    targetsArr.forEach((target) => {
      const rect = target.getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight;
      if (scroll > offset - windowHeight + 300) {
        target.classList.add("scroll-in");
      }
    });
  }

  fadeInFv() {
    const targets = document.querySelectorAll(".fade-in--fv");
    const targetsArr = Array.prototype.slice.call(targets);
    targetsArr.forEach((target) => {
      target.classList.add("scroll-in");
    });
  }

  hiddenBnr() {
    const bnr = document.querySelector(".bnr");
    const contact = document.querySelector(".contact");
    const footer = document.querySelector(".footer");
    if (bnr) {
      let rect = "";
      if (contact) {
        rect = contact.getBoundingClientRect().top;
      } else {
        rect = footer.getBoundingClientRect().top;
      }
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight;
      if (scroll > offset - windowHeight) {
        if (bnr.classList.contains("is-closed")) {
          return;
        } else {
          bnr.classList.add("is-hidden");
        }
      } else {
        if (bnr.classList.contains("is-closed")) {
          return;
        } else {
          bnr.classList.remove("is-hidden");
        }
      }
    }
  }

  closeBnr() {
    const bnr = document.querySelector(".bnr");
    const closeBtn = document.querySelector(".bnr__close");
    if (bnr) {
      closeBtn.addEventListener("click", () => {
        bnr.classList.add("is-closed");
      });
    }
  }

  ieModal() {
    const modal = document.querySelector(".ie-modal");
    const closeBtns = document.querySelectorAll(".js-close");
    const closeBtnsArr = Array.prototype.slice.call(closeBtns);
    const overlay = document.querySelector(".ie-modal__overlay");
    const body = document.querySelector("body");

    const isIe = () => {
      const ua = navigator.userAgent;
      return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    };

    if (isIe() && !sessionStorage.getItem("ie-modal")) {
      modal.classList.add("is-show");
      overlay.classList.add("is-show");
      body.classList.add("is-fixed");

      closeBtnsArr.forEach((closeBtn) => {
        closeBtn.addEventListener("click", () => {
          sessionStorage.setItem("ie-modal", "on");
          modal.classList.remove("is-show");
          overlay.classList.remove("is-show");
          body.classList.remove("is-fixed");
        });
      });
    }
  }
}
const common = new Common();
common.init();
